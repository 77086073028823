import { connectSearchBox } from "instantsearch.js/es/connectors";

const renderSearchBox = (renderOptions, isFirstRender) => {
  const { query, refine, clear, isSearchStalled, widgetParams } = renderOptions;

  const input = document.getElementById("alg-input");
  input.addEventListener('keypress', function(event) {
      if (event.key === 'Enter') {
          event.preventDefault();
          // TODO: Change this when the site will support multiple languages
          // let searchUrl = '/en/search/';
          let searchUrl = '/search/';
          if (query) {
              searchUrl += `?${algoliaIndexName}[query]=${query}`;
          }
          window.location.href = searchUrl;
      }
  });

  if (isFirstRender) {
    input.addEventListener("input", (event) => {
      refine(event.target.value);
    });
  }

  widgetParams.container.querySelector("input").value = query;
};

const customSearchBox = connectSearchBox(renderSearchBox);

export const SearchBox = customSearchBox({
  container: document.querySelector("#searchbox"),
});
